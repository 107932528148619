<template>
  <el-dialog v-bind="$attrs" :close-on-click-modal="false"
             v-on="$listeners"
             :visible.sync="show"
             :title="event === 'add' ? '新增字典': '编辑字典'"
             @close="cancel">
    <div class="form">
      <el-form class="form-group"
               :model="item"
               v-for="(item, index) in form"
               :key="index"
               label-width="80px"
               :ref="'form'+ index"
               :rules="rules">
        <el-form-item prop="shardName"
                      label="分片名">
          <el-select class="inputWid" v-model="item.shardName" clearable>
            <el-option v-for="it in options"
                       :key="it.value"
                       :label="it.label"
                       :value="it.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原数据"
                      prop="dictKey">
          <el-input v-model="item.dictKey" clearable
                    placeholder="原数据"></el-input>
        </el-form-item>
        <el-form-item label="映射数据"
                      prop="dictValue">
          <el-input v-model="item.dictValue" clearable
                    placeholder="映射数据"></el-input>
        </el-form-item>
        <div class="icon"
             v-if="!currentData.dictionaryID">
          <i v-if="!index"
             class="el-icon-circle-plus-outline"
             size="32"
             @click="addItem"></i>
          <i v-else
             class="el-icon-remove-outline"
             size="32"
             @click="delItem(index)"></i>
        </div>
      </el-form>
      <div class="btn-group">
        <!-- <el-button @click="cancel">取消</el-button> -->
        <el-button type="primary"
                   @click="confirm"
                   :loading="load">提交</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'EditForm',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    currentData: {
      type: Object,
      default: () => {}
    },
    event: {
      type: String,
      default: 'add'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'show',
    event: 'change'
  },
  data() {
    return {
      options: [],
      form: [
        {
          shardName: '',
          dictKey: '',
          dictValue: ''
        }
      ],
      rules: {
        shardName: [{ required: true, message: '分片名', trigger: 'change' }],
        dictKey: [{ required: true, message: '原数据', trigger: 'blur' }],
        dictValue: [{ required: true, message: '映射数据', trigger: 'blur' }]
      }
    }
  },
  computed: {
    load() {
      return this.loading
    }
  },
  watch: {
    show(val) {
      if (val && this.currentData.dictionaryID) {
        this.form = [JSON.parse(JSON.stringify(this.currentData))]
      } else {
        this.form = [{}]
      }
    }
  },
  mounted() {
    this.getAliases()
  },
  methods: {
    // 分片名数组
    async getAliases() {
      let { data } = await this.$api.home.shard()
      if (data) {
        let array = []
        for (let item of data) {
          let obj = {}
          obj.value = item
          obj.label = item
          array.push(obj)
        }
        this.options = array
      }
    },
    close() {
      this.$emit('change', false)
    },
    addItem() {
      if (this.form.length >= 5) return
      this.form.push({})
    },
    delItem(index) {
      this.form.splice(index, 1)
    },
    confirm() {
      let index = 0
      let len = this.form.length
      for (let i = 0; i < len; i++) {
        this.$refs['form' + i][0].validate((valid) => {
          if (valid) {
            index++
            if (index !== len) return
            this.$emit(this.event, this.form)
          } else {
            // console.log('error submit!!')
            return false
          }
        })
      }
    },
    cancel() {
      for (let i = 0; i < this.form.length; i++) {
        this.$refs['form' + i][0].resetFields()
      }
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  .form-group {
    position: relative;
    width: 90%;
    .icon {
      position: absolute;
      right: -24px;
      top: 13px;
    }
  }
  .inputWid{
    width: 100% !important;
  }
}
.btn-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
}
</style>
