<template>
  <div class="ym-page">
    <search-form :form="form"
                 @search="search"
                 @select="select"></search-form>

    <table-list :data="list"
                :columns="columns"
                fit
                stripe
                :tableOption="{size: 'mini'}"
                :loading="loading"
                row-key="id"
                :headData="headData"
                :pageData="pageData"
                @handlePageChange="handlePageChange"
                @handleSizeChange="handleSizeChange">
      <!-- 原数据 -->
      <template #dictKey="{row}">
        <div class="div-row"
             v-if="row.dictKey">
          <div class="shenglue-1">{{row.dictKey}}</div>
          <i class="el-icon-document-copy copy-icon" style="margin-left: 10px"
             @click="copy($event, row.dictKey)"></i>
        </div>
        <span v-else-if="!row.dictKey">无</span>
      </template>
      <!-- 映射数据 -->
      <template #dictValue="{row}">
        <div class="shenglue-1"
             v-if="row.dictValue">{{row.dictValue}}</div>
        <span v-else-if="!row.dictValue">无</span>
      </template>
      <template #createTime="{ row }">
        {{tf(row.createTime, 'YYYY-MM-DD HH:mm:ss')}}
      </template>
    </table-list>

    <edit-form v-model="show"
               :event="event"
               :currentData="row"
               :loading="loading"
               @add="add"
               @edit="edit"></edit-form>
  </div>
</template>

<script>
import timeFormat from '@/utils/timeFormat.js'
import TableList from '@/components/TableList'
import EditForm from './components/EditForm'
import SearchForm from './components/SearchForm'
import Clipboard from 'clipboard'
import {
  activeArray,
  getShardName,
  setShardName
} from '@/utils/localStorage.js'

export default {
  components: {
    TableList,
    EditForm,
    SearchForm
  },
  data() {
    return {
      form: {
        shard: null,
        dictKey: '',
        dictValue: ''
      },
      columns: [
        {
          label: '序号',
          type: 'index',
          align: 'center',
          show: true,
          width: 70
        },
        {
          label: '分片名',
          prop: 'shardName',
          show: true,
          align: 'center',
          width: 160
        },
        {
          label: '原数据',
          prop: 'dictKey',
          show: true,
          enableSlot: true,
          minWidth: 160
        },
        {
          label: '映射数据',
          prop: 'dictValue',
          show: true,
          enableSlot: true,
          minWidth: 160
        },
        {
          label: '创建时间',
          prop: 'createTime',
          show: true,
          enableSlot: true,
          width: 200
        },
        {
          prop: 'operators',
          label: '操作',
          fixed: 'right',
          align: 'right',
          width: 200,
          children: [
            {
              icon: 'el-icon-edit',
              label: '编辑',
              clickEvent: this.toEdit,
              showFilter: (item, row, thisVue) => {
                return activeArray('/dict/dict-update')
              }
              // action: activeArray('/dict/dict-update')
            },
            {
              icon: 'el-icon-delete',
              label: '删除',
              color: '#FF8851',
              clickEvent: this.onDelete,
              showFilter: (item, row, thisVue) => {
                return activeArray('/dict/dict-delete')
              }
              // action: activeArray('/dict/dict-delete')
            }
          ]
        }
      ],
      headData: {
        title: '数据字典',
        items: [
          /* 新增按钮 */
          {
            style: 'button',
            label: '新增',
            icon: 'el-icon-plus',
            size: 'medium',
            type: 'primary',
            dataStep: '1',
            // 控制按钮显示隐藏
            showFilter: () => {
              return activeArray('/dict/dict-add')
            },
            clickEvent: () => {
              this.toAdd()
            }
          }
        ]
      },
      pageData: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
        total: 0,
        layout: 'prev, pager, next, total, sizes, jumper, slot',
        currentPage: 1 // 当前页
      },
      list: [],
      show: false,
      loading: false,
      row: {},
      event: 'add'
    }
  },
  created() {
    if (getShardName()) {
      this.form.shard = getShardName()
    }
    this.getList()
  },
  mounted() {
    // 新手指引
    this.$nextTick(() => {
      this.$guide.myIntroJs(this.$route.name)
    })
  },
  methods: {
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    // 存储分片名
    select(value) {
      setShardName(value)
    },
    activeArray(control) {
      return activeArray(control)
    },
    toAdd() {
      this.event = 'add'
      this.row = {}
      this.show = true
    },
    toEdit(parent, row) {
      this.event = 'edit'
      this.row = row
      this.show = true
    },
    // 分页
    handlePageChange(page) {
      this.pageData.currentPage = page
      this.getList()
    },
    // 配置每页条数
    handleSizeChange(number) {
      this.pageData.currentPage = 1
      this.pageData.size = number
      this.pageData.pageSize = number
      this.getList(1)
    },
    // 搜索
    search(form) {
      // 重置表单数据
      this.form = form
      setShardName(this.form.shard)
      this.pageData.currentPage = 1
      this.getList()
    },
    // 获取列表
    async getList() {
      let param = {
        page: this.pageData.currentPage - 1,
        size: this.pageData.size
      }
      let body = {}
      let res = null
      let _form = this.form
      // if (_form.shard === '') {
      //   _form.shard = null
      // }
      if (this.form.dictKey === '') {
        _form.dictKey = null
      }
      if (this.form.dictValue === '') {
        _form.dictValue = null
      }
      body.shardName = _form.shard
      body.dictKey = _form.dictKey
      body.dictValue = _form.dictValue
      res = await this.$api.dictionary.shardData(param, body)
      if (res.code === 200) {
        this.list = res.data.content
        this.pageData.total = res.data.total
      } else {
        this.$message.error('资源请求失败')
      }
    },
    // 新增
    async add(form) {
      if (this.loading) {
        return
      }
      const res = await this.$api.dictionary.addDictionary(form)
      this.loading = true
      if (res.code === 200) {
        this.show = false
        this.pageData.currentPage = 1
        this.getList()
        this.$message.success('新增成功')
      } else {
        this.$message.error(res.message)
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    // 编辑
    async edit(form) {
      if (this.loading) {
        return
      }
      const res = await this.$api.dictionary.updateDictionary(form[0])
      this.loading = true
      if (res.code === 200) {
        let index = this.list.findIndex(
          (item) => item.dictionaryID === form[0].dictionaryID
        )
        this.list.splice(index, 1, res.data)
        this.show = false
        this.$message.success('修改成功')
      } else {
        this.$message.error(res.message)
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    // 删除
    async onDelete(parent, row) {
      try {
        await this.$confirm('此操作将永久删除该条记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const res = await this.$api.dictionary.deleteDictionary({
          dictionaryID: row.dictionaryID
        })
        if (res.code !== 200) return
        let index = this.list.findIndex(
          (item) => item.dictionaryID === row.dictionaryID
        )
        this.list.splice(index, 1)
        this.$message.success('删除成功')
        this.pageData.total--
        if (this.list.length > 0 || this.pageData.currentPage <= 1) return
        this.pageData.currentPage--
        this.getList()
      } catch (error) {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      }
    },
    // 一键复制
    copy(e, text) {
      // console.log(e, text)
      const clipboard = new Clipboard(e.target, { text: () => text })
      this.copyLoading = true
      clipboard.on('success', (e) => {
        // 判断消息提示条数
        if (document.getElementsByClassName('el-message').length > 2) return
        this.$message({ type: 'success', message: '复制成功' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    }
  },
  filters: {
    fmtDate(val) {
      return timeFormat(val, 'YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>
