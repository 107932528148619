<template>
  <div class="search-form">
    <el-form ref="form"
             :model="form"
             label-width="90px"
             label-position="right">
      <el-row style="width:100%">
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="分片名">
            <el-select class="inputWid"
                       @change="handleSelect"
                       @keyup.enter.native="confirm"
                       v-model="form.shard">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="原数据">
            <el-input v-model="form.dictKey"
                      @keyup.enter.native="confirm"
                      clearable
                      placeholder="请输入原数据"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="映射数据">
            <el-input v-model="form.dictValue"
                      @keyup.enter.native="confirm"
                      clearable
                      placeholder="请输入映射数据"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="width:100%">
        <el-col class="search-btn"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24">
          <el-form-item>
            <el-button type="primary"
                       @click="confirm">查询</el-button>
            <el-button class="form-item-Left"
                       @click="rest">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      options: [],
      labelPosition: 'right'
    }
  },
  mounted() {
    this.getAliases()
  },
  methods: {
    // 分片名切换刷新
    handleSelect() {
      this.$emit('select', this.form.shard)
    },
    // 分片名数组
    async getAliases() {
      let { data } = await this.$api.home.shard()
      if (data) {
        let array = [
          {
            value: null,
            label: '全部'
          }
        ]
        for (let item of data) {
          let obj = {}
          obj.value = item
          obj.label = item
          array.push(obj)
        }
        this.options = array
      }
    },
    rest() {
      this.form.shard = null
      this.form.dictKey = ''
      this.form.dictValue = ''
      this.confirm()
    },
    confirm() {
      let _form = {
        shard: this.form.shard,
        dictKey: this.form.dictKey ? this.trim(this.form.dictKey) : '',
        dictValue: this.form.dictValue ? this.trim(this.form.dictValue) : ''
      }
      this.$emit('search', _form)
    },
    trim(s) {
      // 去除目标字符串左右两边的空白字符
      return s.replace(/(^\s*)|(\s*$)/g, '')
    }
  }
}
</script>
